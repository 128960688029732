const M4E = {
    M11: 0, M12: 1, M13: 2, M14: 3,
    M21: 4, M22: 5, M23: 6, M24: 7,
    M31: 8, M32: 9, M33: 10, M34: 11,
    M41: 12, M42: 13, M43: 14, M44: 15
};
const tempMatrices = [new Float32Array(16), new Float32Array(16), new Float32Array(16)];
export function getIdentity() {
    return new Float32Array([
        1.0, 0.0, 0.0, 0.0,
        0.0, 1.0, 0.0, 0.0,
        0.0, 0.0, 1.0, 0.0,
        0.0, 0.0, 0.0, 1.0
    ]);
}
export function setIdentity(target) {
    target.fill(0);
    target[0] = 1;
    target[5] = 1;
    target[10] = 1;
    target[15] = 1;
}
export function setTranslation(target, x, y, z) {
    target.fill(0);
    target[0] = 1;
    target[5] = 1;
    target[10] = 1;
    target[15] = 1;
    target[12] = x;
    target[13] = y ?? 0;
    target[14] = z ?? 0;
}
export function setScaling(target, x, y, z) {
    target.fill(0);
    target[0] = x;
    target[5] = y;
    target[10] = z;
    target[15] = 1;
}
export function setRotationMajorAxis(target, angle, axis) {
    target.fill(0);
    let sinA = Math.sin(angle);
    let cosA = Math.cos(angle);
    let sinA2 = sinA * sinA;
    switch (axis) {
        case "x":
            target[M4E.M11] = 1;
            target[M4E.M22] = 1 - 2 * sinA2;
            target[M4E.M23] = 2 * sinA * cosA;
            target[M4E.M32] = -2 * sinA * cosA;
            target[M4E.M33] = 1 - 2 * sinA2;
            target[M4E.M14] = target[M4E.M24] = target[M4E.M34] = 0;
            target[M4E.M41] = target[M4E.M42] = target[M4E.M43] = 0;
            target[M4E.M44] = 1;
            break;
        case "y":
            target[M4E.M11] = 1 - 2 * sinA2;
            target[M4E.M13] = -2 * sinA * cosA;
            target[M4E.M22] = 1;
            target[M4E.M31] = 2 * sinA * cosA;
            target[M4E.M33] = 1 - 2 * sinA2;
            target[M4E.M14] = target[M4E.M24] = target[M4E.M34] = 0;
            target[M4E.M41] = target[M4E.M42] = target[M4E.M43] = 0;
            target[M4E.M44] = 1;
            break;
        case "z":
            target[M4E.M11] = 1 - 2 * sinA2;
            target[M4E.M12] = 2 * sinA * cosA;
            target[M4E.M21] = -2 * sinA * cosA;
            target[M4E.M22] = 1 - 2 * sinA2;
            target[M4E.M33] = 1;
            target[M4E.M14] = target[M4E.M24] = target[M4E.M34] = 0;
            target[M4E.M41] = target[M4E.M42] = target[M4E.M43] = 0;
            target[M4E.M44] = 1;
            break;
    }
}
export function setRotationAxis(target, angle, axisX, axisY, axisZ) {
    target.fill(0);
    let sinA = Math.sin(angle);
    let cosA = Math.cos(angle);
    let sinA2 = sinA * sinA;
    let x2 = axisX * axisX;
    let y2 = axisY * axisY;
    let z2 = axisZ * axisZ;
    target[M4E.M11] = 1 - 2 * (y2 + z2) * sinA2;
    target[M4E.M12] = 2 * (axisX * axisY * sinA2 + axisZ * sinA * cosA);
    target[M4E.M13] = 2 * (axisX * axisZ * sinA2 - axisY * sinA * cosA);
    target[M4E.M21] = 2 * (axisY * axisX * sinA2 - axisZ * sinA * cosA);
    target[M4E.M22] = 1 - 2 * (z2 + x2) * sinA2;
    target[M4E.M23] = 2 * (axisY * axisZ * sinA2 + axisX * sinA * cosA);
    target[M4E.M31] = 2 * (axisZ * axisX * sinA2 + axisY * sinA * cosA);
    target[M4E.M32] = 2 * (axisZ * axisY * sinA2 - axisX * sinA * cosA);
    target[M4E.M33] = 1 - 2 * (x2 + y2) * sinA2;
    target[M4E.M14] = target[M4E.M24] = target[M4E.M34] = 0;
    target[M4E.M41] = target[M4E.M42] = target[M4E.M43] = 0;
    target[M4E.M44] = 1;
}
export function setRotationRollYawPitch(target, roll, pitch, yaw) {
    setRotationMajorAxis(tempMatrices[0], roll, "z");
    setRotationMajorAxis(tempMatrices[1], pitch, "y");
    multiply(tempMatrices[1], tempMatrices[0], tempMatrices[2]);
    setRotationMajorAxis(tempMatrices[0], yaw, "x");
    multiply(tempMatrices[0], tempMatrices[2], target);
}
export function setPerspective(target, fovy, aspect, zNear, zFar) {
    const f = Math.tan(Math.PI * 0.5 - 0.5 * (fovy * Math.PI / 180));
    target[0] = f / aspect;
    target[1] = 0;
    target[2] = 0;
    target[3] = 0;
    target[4] = 0;
    target[5] = f;
    target[6] = 0;
    target[7] = 0;
    target[8] = 0;
    target[9] = 0;
    target[11] = -1;
    target[12] = 0;
    target[13] = 0;
    target[15] = 0;
    if (zFar === Infinity) {
        target[10] = -1;
        target[14] = -zNear;
    }
    else {
        const rangeInv = 1 / (zNear - zFar);
        target[10] = zFar * rangeInv;
        target[14] = zFar * zNear * rangeInv;
    }
}
export function setOrtho(target, left, right, bottom, top, near, far) {
    target[0] = 2 / (right - left);
    target[1] = 0;
    target[2] = 0;
    target[3] = 0;
    target[4] = 0;
    target[5] = 2 / (top - bottom);
    target[6] = 0;
    target[7] = 0;
    target[8] = 0;
    target[9] = 0;
    target[10] = 1 / (near - far);
    target[11] = 0;
    target[12] = (right + left) / (left - right);
    target[13] = (top + bottom) / (bottom - top);
    target[14] = near / (near - far);
    target[15] = 1;
}
export function multiply(a, b, target) {
    if (target === a || target === b) {
        const m11 = a[M4E.M11] * b[M4E.M11] + a[M4E.M12] * b[M4E.M21] + a[M4E.M13] * b[M4E.M31] + a[M4E.M14] * b[M4E.M41];
        const m12 = a[M4E.M11] * b[M4E.M12] + a[M4E.M12] * b[M4E.M22] + a[M4E.M13] * b[M4E.M32] + a[M4E.M14] * b[M4E.M42];
        const m13 = a[M4E.M11] * b[M4E.M13] + a[M4E.M12] * b[M4E.M23] + a[M4E.M13] * b[M4E.M33] + a[M4E.M14] * b[M4E.M43];
        const m14 = a[M4E.M11] * b[M4E.M14] + a[M4E.M12] * b[M4E.M24] + a[M4E.M13] * b[M4E.M34] + a[M4E.M14] * b[M4E.M44];
        const m21 = a[M4E.M21] * b[M4E.M11] + a[M4E.M22] * b[M4E.M21] + a[M4E.M23] * b[M4E.M31] + a[M4E.M24] * b[M4E.M41];
        const m22 = a[M4E.M21] * b[M4E.M12] + a[M4E.M22] * b[M4E.M22] + a[M4E.M23] * b[M4E.M32] + a[M4E.M24] * b[M4E.M42];
        const m23 = a[M4E.M21] * b[M4E.M13] + a[M4E.M22] * b[M4E.M23] + a[M4E.M23] * b[M4E.M33] + a[M4E.M24] * b[M4E.M43];
        const m24 = a[M4E.M21] * b[M4E.M14] + a[M4E.M22] * b[M4E.M24] + a[M4E.M23] * b[M4E.M34] + a[M4E.M24] * b[M4E.M44];
        const m31 = a[M4E.M31] * b[M4E.M11] + a[M4E.M32] * b[M4E.M21] + a[M4E.M33] * b[M4E.M31] + a[M4E.M34] * b[M4E.M41];
        const m32 = a[M4E.M31] * b[M4E.M12] + a[M4E.M32] * b[M4E.M22] + a[M4E.M33] * b[M4E.M32] + a[M4E.M34] * b[M4E.M42];
        const m33 = a[M4E.M31] * b[M4E.M13] + a[M4E.M32] * b[M4E.M23] + a[M4E.M33] * b[M4E.M33] + a[M4E.M34] * b[M4E.M43];
        const m34 = a[M4E.M31] * b[M4E.M14] + a[M4E.M32] * b[M4E.M24] + a[M4E.M33] * b[M4E.M34] + a[M4E.M34] * b[M4E.M44];
        const m41 = a[M4E.M41] * b[M4E.M11] + a[M4E.M42] * b[M4E.M21] + a[M4E.M43] * b[M4E.M31] + a[M4E.M44] * b[M4E.M41];
        const m42 = a[M4E.M41] * b[M4E.M12] + a[M4E.M42] * b[M4E.M22] + a[M4E.M43] * b[M4E.M32] + a[M4E.M44] * b[M4E.M42];
        const m43 = a[M4E.M41] * b[M4E.M13] + a[M4E.M42] * b[M4E.M23] + a[M4E.M43] * b[M4E.M33] + a[M4E.M44] * b[M4E.M43];
        const m44 = a[M4E.M41] * b[M4E.M14] + a[M4E.M42] * b[M4E.M24] + a[M4E.M43] * b[M4E.M34] + a[M4E.M44] * b[M4E.M44];
        target[M4E.M11] = m11;
        target[M4E.M12] = m12;
        target[M4E.M13] = m13;
        target[M4E.M14] = m14;
        target[M4E.M21] = m21;
        target[M4E.M22] = m22;
        target[M4E.M23] = m23;
        target[M4E.M24] = m24;
        target[M4E.M31] = m31;
        target[M4E.M32] = m32;
        target[M4E.M33] = m33;
        target[M4E.M34] = m34;
        target[M4E.M41] = m41;
        target[M4E.M42] = m42;
        target[M4E.M43] = m43;
        target[M4E.M44] = m44;
    }
    else {
        target[M4E.M11] = a[M4E.M11] * b[M4E.M11] + a[M4E.M12] * b[M4E.M21] + a[M4E.M13] * b[M4E.M31] + a[M4E.M14] * b[M4E.M41];
        target[M4E.M12] = a[M4E.M11] * b[M4E.M12] + a[M4E.M12] * b[M4E.M22] + a[M4E.M13] * b[M4E.M32] + a[M4E.M14] * b[M4E.M42];
        target[M4E.M13] = a[M4E.M11] * b[M4E.M13] + a[M4E.M12] * b[M4E.M23] + a[M4E.M13] * b[M4E.M33] + a[M4E.M14] * b[M4E.M43];
        target[M4E.M14] = a[M4E.M11] * b[M4E.M14] + a[M4E.M12] * b[M4E.M24] + a[M4E.M13] * b[M4E.M34] + a[M4E.M14] * b[M4E.M44];
        target[M4E.M21] = a[M4E.M21] * b[M4E.M11] + a[M4E.M22] * b[M4E.M21] + a[M4E.M23] * b[M4E.M31] + a[M4E.M24] * b[M4E.M41];
        target[M4E.M22] = a[M4E.M21] * b[M4E.M12] + a[M4E.M22] * b[M4E.M22] + a[M4E.M23] * b[M4E.M32] + a[M4E.M24] * b[M4E.M42];
        target[M4E.M23] = a[M4E.M21] * b[M4E.M13] + a[M4E.M22] * b[M4E.M23] + a[M4E.M23] * b[M4E.M33] + a[M4E.M24] * b[M4E.M43];
        target[M4E.M24] = a[M4E.M21] * b[M4E.M14] + a[M4E.M22] * b[M4E.M24] + a[M4E.M23] * b[M4E.M34] + a[M4E.M24] * b[M4E.M44];
        target[M4E.M31] = a[M4E.M31] * b[M4E.M11] + a[M4E.M32] * b[M4E.M21] + a[M4E.M33] * b[M4E.M31] + a[M4E.M34] * b[M4E.M41];
        target[M4E.M32] = a[M4E.M31] * b[M4E.M12] + a[M4E.M32] * b[M4E.M22] + a[M4E.M33] * b[M4E.M32] + a[M4E.M34] * b[M4E.M42];
        target[M4E.M33] = a[M4E.M31] * b[M4E.M13] + a[M4E.M32] * b[M4E.M23] + a[M4E.M33] * b[M4E.M33] + a[M4E.M34] * b[M4E.M43];
        target[M4E.M34] = a[M4E.M31] * b[M4E.M14] + a[M4E.M32] * b[M4E.M24] + a[M4E.M33] * b[M4E.M34] + a[M4E.M34] * b[M4E.M44];
        target[M4E.M41] = a[M4E.M41] * b[M4E.M11] + a[M4E.M42] * b[M4E.M21] + a[M4E.M43] * b[M4E.M31] + a[M4E.M44] * b[M4E.M41];
        target[M4E.M42] = a[M4E.M41] * b[M4E.M12] + a[M4E.M42] * b[M4E.M22] + a[M4E.M43] * b[M4E.M32] + a[M4E.M44] * b[M4E.M42];
        target[M4E.M43] = a[M4E.M41] * b[M4E.M13] + a[M4E.M42] * b[M4E.M23] + a[M4E.M43] * b[M4E.M33] + a[M4E.M44] * b[M4E.M43];
        target[M4E.M44] = a[M4E.M41] * b[M4E.M14] + a[M4E.M42] * b[M4E.M24] + a[M4E.M43] * b[M4E.M34] + a[M4E.M44] * b[M4E.M44];
    }
}
export function translateBy(target, x, y, z) {
    setTranslation(tempMatrices[0], x, y, z);
    multiply(target, tempMatrices[0], target);
}
export function scaleBy(target, x, y, z) {
    setScaling(tempMatrices[0], x, y, z);
    multiply(target, tempMatrices[0], target);
}
export function reduceToNormalMatrix(m, dst) {
    if (dst !== m) {
        dst[0] = m[0];
        dst[1] = m[1];
        dst[2] = m[2];
        dst[4] = m[4];
        dst[5] = m[5];
        dst[6] = m[6];
        dst[8] = m[8];
        dst[9] = m[9];
        dst[10] = m[10];
    }
    dst[3] = 0;
    dst[7] = 0;
    dst[11] = 0;
    dst[12] = 0;
    dst[13] = 0;
    dst[14] = 0;
    dst[15] = 1;
}

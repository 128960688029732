export function processProceduralConfig(config) {
    const includePosition = config ? config.position : true;
    const includeNormal = config ? config.normal : false;
    const includeColor = config ? config.color : true;
    const includeTex1 = config ? config.tex1 : false;
    const includeTex2 = config ? config.tex2 : false;
    const positionOffset = 0;
    const normalOffset = includePosition ? 3 : 0;
    const colorOffset = normalOffset + (includeNormal ? 3 : 0);
    const tex1Offset = colorOffset + (includeColor ? 3 : 0);
    const tex2Offset = tex1Offset + (includeTex1 ? 2 : 0);
    const elementsPerVertex = (includePosition ? 3 : 0) + (includeNormal ? 3 : 0) + (includeColor ? 3 : 0) + (includeTex1 ? 2 : 0) + (includeTex2 ? 2 : 0);
    const attributes = [];
    if (includePosition) {
        attributes.push({
            format: "float32x3",
            offset: positionOffset * Float32Array.BYTES_PER_ELEMENT,
            shaderLocation: attributes.length
        });
    }
    if (includeNormal) {
        attributes.push({
            format: "float32x3",
            offset: normalOffset * Float32Array.BYTES_PER_ELEMENT,
            shaderLocation: attributes.length
        });
    }
    if (includeColor) {
        attributes.push({
            format: "float32x3",
            offset: colorOffset * Float32Array.BYTES_PER_ELEMENT,
            shaderLocation: attributes.length
        });
    }
    if (includeTex1) {
        attributes.push({
            format: "float32x2",
            offset: tex1Offset * Float32Array.BYTES_PER_ELEMENT,
            shaderLocation: attributes.length
        });
    }
    if (includeTex2) {
        attributes.push({
            format: "float32x2",
            offset: tex2Offset * Float32Array.BYTES_PER_ELEMENT,
            shaderLocation: attributes.length
        });
    }
    const vertexBufferLayout = {
        arrayStride: elementsPerVertex * Float32Array.BYTES_PER_ELEMENT,
        attributes
    };
    return {
        includePosition,
        includeNormal,
        includeColor,
        includeTex1,
        includeTex2,
        elementsPerVertex,
        vertexBufferLayout
    };
}

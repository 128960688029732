import { processProceduralConfig } from "./types";
export function createCylinder(config) {
    const { includePosition, includeNormal, includeColor, includeTex1, includeTex2, elementsPerVertex, vertexBufferLayout } = processProceduralConfig(config);
    const startRadius = config?.startRadius ?? 1;
    const endRadius = config?.endRadius ?? 1;
    const segments = config?.segments ?? 32;
    const length = config?.length ?? 2;
    const includeCaps = config?.includeCaps ?? true;
    const col = [1, 1, 1];
    if (segments < 3)
        throw new Error("Invalid number of minor- or major-segments.");
    if (startRadius == 0 && endRadius == 0)
        throw new Error("At least one radius must be different from 0.");
    if ((startRadius < 0 && endRadius > 0) || (startRadius > 0 && endRadius < 0))
        throw new Error("Startradius and endradius must have same sign.");
    if (length == 0)
        throw new Error("Length must be different from 0.");
    const includeStartCap = startRadius != 0 && includeCaps;
    const includeEndCap = endRadius != 0 && includeCaps;
    const mainVertexCount = segments * 2;
    const capVertexCount = segments + 1;
    const vertexCount = mainVertexCount + (includeStartCap ? capVertexCount : 0) + (includeEndCap ? capVertexCount : 0);
    const vertices = new Float32Array(vertexCount * elementsPerVertex);
    let index1 = 0;
    let index2 = segments * elementsPerVertex;
    let index3 = index2 + (segments * elementsPerVertex) + elementsPerVertex;
    let index4 = index3 + (segments * elementsPerVertex) + elementsPerVertex;
    for (let i = 0, val = 0, delta = Math.PI * 2 / segments; i < segments; i++, val += delta) {
        const dx = Math.cos(val);
        const dy = Math.sin(val);
        if (includePosition) {
            vertices[index1++] = dx * startRadius;
            vertices[index1++] = dy * startRadius;
            vertices[index1++] = -length / 2;
            vertices[index2++] = dx * endRadius;
            vertices[index2++] = dy * endRadius;
            vertices[index2++] = length / 2;
            if (includeStartCap) {
                vertices[index3++] = dx * startRadius;
                vertices[index3++] = dy * startRadius;
                vertices[index3++] = -length / 2;
            }
            if (includeEndCap) {
                vertices[index4++] = dx * endRadius;
                vertices[index4++] = dy * endRadius;
                vertices[index4++] = length / 2;
            }
        }
        if (includeNormal) {
            vertices[index1++] = dx;
            vertices[index1++] = dy;
            vertices[index1++] = 0;
            vertices[index2++] = dx;
            vertices[index2++] = dy;
            vertices[index2++] = 0;
            if (includeStartCap) {
                vertices[index3++] = 0;
                vertices[index3++] = 0;
                vertices[index3++] = -1;
            }
            if (includeEndCap) {
                vertices[index4++] = 0;
                vertices[index4++] = 0;
                vertices[index4++] = 1;
            }
        }
        if (includeColor) {
            vertices[index1++] = col[0];
            vertices[index1++] = col[1];
            vertices[index1++] = col[2];
            vertices[index2++] = col[0];
            vertices[index2++] = col[1];
            vertices[index2++] = col[2];
            if (includeStartCap) {
                vertices[index3++] = col[0];
                vertices[index3++] = col[1];
                vertices[index3++] = col[2];
            }
            if (includeEndCap) {
                vertices[index4++] = col[0];
                vertices[index4++] = col[1];
                vertices[index4++] = col[2];
            }
        }
        if (includeTex1) {
            vertices[index1++] = 0;
            vertices[index1++] = i / segments;
            vertices[index2++] = 1;
            vertices[index2++] = i / segments;
            if (includeStartCap) {
                vertices[index3++] = dx / 2 + 0.5;
                vertices[index3++] = dy / 2 + 0.5;
            }
            if (includeEndCap) {
                vertices[index4++] = dx / 2 + 0.5;
                vertices[index4++] = dy / 2 + 0.5;
            }
        }
        if (includeTex2) {
            vertices[index1++] = 0;
            vertices[index1++] = i / segments;
            vertices[index2++] = 1;
            vertices[index2++] = i / segments;
            if (includeStartCap) {
                vertices[index3++] = dx / 2 + 0.5;
                vertices[index3++] = dy / 2 + 0.5;
            }
            if (includeEndCap) {
                vertices[index4++] = dx / 2 + 0.5;
                vertices[index4++] = dy / 2 + 0.5;
            }
        }
    }
    if (includePosition) {
        if (includeStartCap) {
            vertices[index2++] = 0;
            vertices[index2++] = 0;
            vertices[index2++] = -length / 2;
        }
        if (includeEndCap) {
            vertices[index3++] = 0;
            vertices[index3++] = 0;
            vertices[index3++] = length / 2;
        }
    }
    if (includeNormal) {
        if (includeStartCap) {
            vertices[index2++] = 0;
            vertices[index2++] = 0;
            vertices[index2++] = -1;
        }
        if (includeEndCap) {
            vertices[index3++] = 0;
            vertices[index3++] = 0;
            vertices[index3++] = 1;
        }
    }
    if (includeColor) {
        vertices[index2++] = col[0];
        vertices[index2++] = col[1];
        vertices[index2++] = col[2];
        vertices[index3++] = col[0];
        vertices[index3++] = col[1];
        vertices[index3++] = col[2];
    }
    if (includeTex1) {
        vertices[index3++] = 0.5;
        vertices[index3++] = 0.5;
        vertices[index4++] = 0.5;
        vertices[index4++] = 0.5;
    }
    if (includeTex2) {
        vertices[index3++] = 0.5;
        vertices[index3++] = 0.5;
        vertices[index4++] = 0.5;
        vertices[index4++] = 0.5;
    }
    index1 = 0;
    const indices = new Int16Array(segments * (6 + (includeStartCap ? 3 : 0) + (includeEndCap ? 3 : 0)));
    for (let n = 0; n < segments; n++) {
        const n0 = n;
        const n1 = ((n + 1) % segments);
        indices[index1++] = n0 % vertices.length;
        indices[index1++] = n1 % vertices.length;
        indices[index1++] = (n1 + segments) % mainVertexCount;
        indices[index1++] = (n1 + segments) % mainVertexCount;
        indices[index1++] = (n0 + segments) % mainVertexCount;
        indices[index1++] = n0 % vertices.length;
    }
    let centerIndex = segments * 2;
    if (includeStartCap) {
        for (let n = 0; n < segments; n++) {
            indices[index1++] = centerIndex;
            indices[index1++] = centerIndex + 1 + ((n + 1) % segments);
            indices[index1++] = centerIndex + 1 + n;
        }
    }
    centerIndex += segments + 1;
    if (includeEndCap) {
        for (let n = 0; n < segments; n++) {
            indices[index1++] = centerIndex;
            indices[index1++] = centerIndex + 1 + n;
            indices[index1++] = centerIndex + 1 + ((n + 1) % segments);
        }
    }
    return { elementsPerVertex, vertices, indices, vertexBufferLayout };
}
;

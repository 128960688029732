import { createCylinder } from "../../services/geometry";
import { getIdentity, multiply, setPerspective, setRotationRollYawPitch, setTranslation } from "../../services/matrix";
import ShaderSrc from "./shader.wgsl";
export async function start() {
    const canvas = document.querySelector("canvas");
    canvas.style.width = "100vw";
    canvas.style.height = "100vh";
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const adapter = await navigator.gpu.requestAdapter();
    if (!adapter) {
        throw new Error("No appropriate GPUAdapter found.");
    }
    const device = await adapter.requestDevice();
    const context = canvas.getContext("webgpu");
    const canvasFormat = navigator.gpu.getPreferredCanvasFormat();
    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * devicePixelRatio;
    canvas.height = canvas.clientHeight * devicePixelRatio;
    context.configure({
        device: device,
        format: canvasFormat,
        alphaMode: "premultiplied"
    });
    async function loadCubemapTexture(urls) {
        let realurls = typeof urls == "string" ? [
            `${urls}posx.jpg`,
            `${urls}negx.jpg`,
            `${urls}posy.jpg`,
            `${urls}negy.jpg`,
            `${urls}posz.jpg`,
            `${urls}negz.jpg`
        ] : urls;
        if (realurls.length != 6)
            throw new Error("Expected 6 images for cubemap");
        const images = await Promise.all(realurls.map(loadImageBitmap));
        const width = images[0].width;
        const height = images[0].height;
        const texture = device.createTexture({
            size: [width, height, 6],
            format: "rgba8unorm",
            usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST | GPUTextureUsage.RENDER_ATTACHMENT,
        });
        for (let i = 0; i < images.length; i++) {
            device.queue.copyExternalImageToTexture({ source: images[i] }, { texture: texture, origin: [0, 0, i] }, [width, height]);
        }
        return texture;
    }
    async function loadImageBitmap(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return await createImageBitmap(blob);
    }
    const cubemap = await loadCubemapTexture("./nightpath-small/");
    const imageBitmap = await loadImageBitmap("/marble.jpg");
    const marbleTexture = device.createTexture({
        size: [imageBitmap.width, imageBitmap.height, 1],
        format: "rgba8unorm",
        usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST | GPUTextureUsage.RENDER_ATTACHMENT,
    });
    device.queue.copyExternalImageToTexture({ source: imageBitmap }, { texture: marbleTexture }, [imageBitmap.width, imageBitmap.height]);
    const sampler = device.createSampler({
        magFilter: "linear",
        minFilter: "linear",
    });
    const { vertices, indices, vertexBufferLayout } = createCylinder({ position: true, normal: true, color: true, segments: 64, tex1: true, endRadius: 1, includeCaps: true });
    const vertexBuffer = device.createBuffer({
        label: "vertices",
        size: vertices.byteLength,
        usage: GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST
    });
    device.queue.writeBuffer(vertexBuffer, 0, vertices);
    const indexBuffer = device.createBuffer({
        label: "indices",
        size: indices.byteLength,
        usage: GPUBufferUsage.INDEX | GPUBufferUsage.COPY_DST
    });
    device.queue.writeBuffer(indexBuffer, 0, indices);
    const lightUniformBuffer = device.createBuffer({
        size: (4 + 4) * Float32Array.BYTES_PER_ELEMENT,
        usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
    });
    const cameraUniformBuffer = device.createBuffer({
        size: (16 + 16 + 16) * Float32Array.BYTES_PER_ELEMENT,
        usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
    });
    const bindGroupLayout = device.createBindGroupLayout({
        entries: [{
                binding: 0,
                visibility: GPUShaderStage.FRAGMENT,
                buffer: {
                    type: "uniform"
                },
            },
            {
                binding: 1,
                visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
                buffer: {
                    type: "uniform"
                },
            },
            {
                binding: 2,
                visibility: GPUShaderStage.FRAGMENT,
                sampler: {}
            },
            {
                binding: 3,
                visibility: GPUShaderStage.FRAGMENT,
                texture: {}
            },
            {
                binding: 4,
                visibility: GPUShaderStage.FRAGMENT,
                texture: {
                    viewDimension: "cube"
                }
            }]
    });
    const bindGroup = device.createBindGroup({
        layout: bindGroupLayout,
        entries: [
            {
                binding: 0,
                resource: {
                    buffer: lightUniformBuffer,
                },
            },
            {
                binding: 1,
                resource: {
                    buffer: cameraUniformBuffer,
                },
            },
            {
                binding: 2,
                resource: sampler,
            },
            {
                binding: 3,
                resource: marbleTexture.createView(),
            },
            {
                binding: 4,
                resource: cubemap.createView({
                    dimension: "cube"
                }),
            },
        ],
    });
    const pipelineLayout = device.createPipelineLayout({
        bindGroupLayouts: [
            bindGroupLayout,
        ]
    });
    const shaderModule = device.createShaderModule({
        label: "Shader",
        code: ShaderSrc
    });
    const pipeline = device.createRenderPipeline({
        layout: pipelineLayout,
        vertex: {
            module: shaderModule,
            entryPoint: "vertexMain",
            buffers: [vertexBufferLayout]
        },
        fragment: {
            module: shaderModule,
            entryPoint: "fragmentMain",
            targets: [{
                    format: canvasFormat
                }],
        },
        primitive: {
            topology: "triangle-list",
            cullMode: "back"
        },
        depthStencil: {
            depthWriteEnabled: true,
            depthCompare: "less",
            format: "depth24plus",
        }
    });
    let { depthTexture, colorAttachment, depthAttachment, renderPassDescriptor } = createAttachments();
    function createAttachments() {
        const depthTexture = device.createTexture({
            size: [canvas.width, canvas.height],
            format: "depth24plus",
            usage: GPUTextureUsage.RENDER_ATTACHMENT,
        });
        const colorAttachment = {
            view: undefined,
            clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
            loadOp: "clear",
            storeOp: "store",
        };
        const depthAttachment = {
            view: depthTexture.createView(),
            depthClearValue: 1.0,
            depthLoadOp: "clear",
            depthStoreOp: "store"
        };
        const renderPassDescriptor = {
            colorAttachments: [colorAttachment],
            depthStencilAttachment: depthAttachment
        };
        depthAttachment.view = depthTexture.createView();
        return {
            depthTexture,
            colorAttachment,
            depthAttachment,
            renderPassDescriptor
        };
    }
    const normalMatrix = getIdentity();
    const modelMatrix = getIdentity();
    const projectionMatrix = getIdentity();
    const modelViewProjectionMatrix = getIdentity();
    let angle = 0;
    function updateTransformations(deltaTimeMs) {
        const aspect = canvas.width / canvas.height;
        setPerspective(projectionMatrix, 72, aspect, 1, 50);
        angle += 0.0005 * deltaTimeMs;
        setTranslation(modelMatrix, 0.0, 0, -3);
        setRotationRollYawPitch(normalMatrix, angle, angle / 2, angle / 3);
        multiply(normalMatrix, modelMatrix, modelMatrix);
        multiply(modelMatrix, projectionMatrix, modelViewProjectionMatrix);
    }
    const lightPosition = new Float32Array([0, 10, 10000000000000.0, 1]);
    const ambientColor = new Float32Array([0.05, 0.05, 0.05, 1]);
    const tmpBuffer = new Float32Array(1024);
    function updateUniforms() {
        tmpBuffer.set(modelViewProjectionMatrix, 0);
        tmpBuffer.set(modelMatrix, 16);
        tmpBuffer.set(normalMatrix, 32);
        device.queue.writeBuffer(cameraUniformBuffer, 0, tmpBuffer.buffer, 0, (16 + 16 + 16) * Float32Array.BYTES_PER_ELEMENT);
        tmpBuffer.set(lightPosition, 0);
        tmpBuffer.set(ambientColor, 4);
        device.queue.writeBuffer(lightUniformBuffer, 0, tmpBuffer.buffer, 0, (4 + 4) * Float32Array.BYTES_PER_ELEMENT);
    }
    let previousTime;
    function frame(time) {
        const deltaTime = previousTime == undefined ? 0 : (time - previousTime);
        previousTime = time;
        colorAttachment.view = context.getCurrentTexture().createView();
        updateTransformations(deltaTime);
        updateUniforms();
        const commandEncoder = device.createCommandEncoder();
        const passEncoder = commandEncoder.beginRenderPass(renderPassDescriptor);
        passEncoder.setPipeline(pipeline);
        passEncoder.setBindGroup(0, bindGroup);
        passEncoder.setVertexBuffer(0, vertexBuffer);
        passEncoder.setIndexBuffer(indexBuffer, "uint16");
        passEncoder.drawIndexed(indices.length);
        passEncoder.end();
        device.queue.submit([commandEncoder.finish()]);
        requestAnimationFrame(frame);
    }
    window.addEventListener("resize", (e) => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        var buffers = createAttachments();
        depthTexture = buffers.depthTexture;
        depthAttachment = buffers.depthAttachment;
        colorAttachment = buffers.colorAttachment;
        renderPassDescriptor = buffers.renderPassDescriptor;
    });
    requestAnimationFrame(frame);
}
